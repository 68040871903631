.each-fade-mobile {

  width: 100%;
  
}
  
.each-fade-mobile > div {
  width: 100%;
}
  
.each-fade-mobile > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  
.each-fade-mobile p {
  width: 100%;
  font-size: 1.5em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1;
  background-color:hsla(0, 0%, 0%, 0.15);
  color:#d1d1d1;
}




.each-fade {
  display: flex;
  width: 100%;
  
}
.each-fade > div {
  width: 75%;
}
.each-fade > div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.each-fade p {
  width: 125%;
  font-size: 1.5em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color:hsla(0, 0%, 0%, 0.15);
  color:#d1d1d1;
}

.fineprint {
  width: 100%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color:hsla(0, 0%, 0%, 0.15);
  color:#d1d1d1;
}
  